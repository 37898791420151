import React from "react";
import PropTypes from "prop-types";
import TestimonialCardThree from "../../../components/testimonial/layout-03";
import bgImage from "../image/testimonial-bg.png";

const TestimonialSectionThree = ({ data }) => {
    return (
        <section
            className="testimonial-section section-space-ptb bg-fixed bg-cover"
            style={
                {
                    // backgroundImage: `url(${bgImage})`,
                }
            }
        >
            <div className="container-full">
                {data?.items?.slice(0, 1).map((item) => (
                    <TestimonialCardThree
                        key={item.id}
                        description={item?.description}
                        images={item?.images}
                        name={item?.name}
                        designation={item?.designation}
                    />
                ))}
            </div>
        </section>
    );
};

TestimonialSectionThree.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default TestimonialSectionThree;
