import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AdvantagesCard = ({ images, title, description }) => {
    return (
        <div className="card group">
            <div className="card-image relative overflow-hidden bg-primary">
                <GatsbyImage
                    className="w-full transition delay-300 ease-in-out"
                    image={getImage(images[0].src)}
                    alt={images[0].alt || title}
                />
            </div>
            <div className="content mt-9">
                {title && (
                    <div className="text-[24px] xl:text-[32px]">
                        {title == "多溫層共配" ? (
                            <h2 className="sm:pt-9">{title}</h2>
                        ) : (
                            <h2>{title}</h2>
                        )}
                    </div>
                )}
                {description && <p className="mt-4">{description}</p>}
            </div>
        </div>
    );
};

AdvantagesCard.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
    title: PropTypes.string,
    description: PropTypes.string,
};

export default AdvantagesCard;
