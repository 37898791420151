import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const FunFact = ({ title, countNumber, icon }) => {
    const [focus, setFocus] = useState(false);
    const visibleChangeHandler = (isVisible) => {
        if (isVisible) {
            if (!focus) {
                setFocus(true);
            }
        }
    };
    const style = {
        iconStyle: `relative shrink-0 z-[1] flex overflow-hidden items-center text-[40px]
        justify-center w-[85px] h-[85px] leading-[85px] rounded-full text-[#3F3C3C] bg-[#F3F3F9] mr-4 lg:mr-6 xl:mr-9 delay-500`,
        iconStyleBefore: `before:h-full before:w-full before:opacity-0 group-hover:text-white group-hover:before:opacity-100 before:border-full before:bg-primary 
        before:absolute before:-z-[1] before:top-0 before:right-0 before:bottom-0 before:left:0 content-[""]
         before:transition before:delay-500 before:duration-300 before:ease-in-out`,
        heading: `counterup text-primary font-bold text-2xl lg:text-[42px] xl:text-[48px] leading-none`,
    };

    return (
        <div className="single-counterup flex group items-center py-9 border-t">
            {icon && (
                <div
                    className={`funfact-icon ${style.iconStyle} ${style.iconStyleBefore} `}
                >
                    <i className={`leading-[0] ${icon}`}></i>
                </div>
            )}
            <div className="content-text">
                <CountUp
                    start={focus ? 0 : null}
                    end={countNumber}
                    duration={3}
                >
                    {({ countUpRef }) => (
                        <h2 className={`${style.heading} flex`}>
                            <span ref={countUpRef} />
                            <VisibilitySensor
                                onChange={(isVisible) =>
                                    visibleChangeHandler(isVisible)
                                }
                            >
                                <span className="sr-only">+</span>
                            </VisibilitySensor>
                            <div>+</div>
                        </h2>
                    )}
                </CountUp>
                <span>{title}</span>
            </div>
        </div>
    );
};

FunFact.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    countNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FunFact;
