import React from "react";
import PropTypes from "prop-types";
import FunFact from "../../../components/funfact/layout-01";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutUsSection = ({ data }) => {
    return (
        <section className="about-us-section">
            <div className="container-full">
                <div className="section-space-ptb border-b grid grid-cols-1 md:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    <div className="sidebar-text mb-10 xs:pl-3">
                        <div className="sidebar-text-item mb-10">
                            <h6 className="font-normal text-primary text-sm uppercase mb-0">
                                多溫層車隊可供選擇
                            </h6>
                            <h5 className="text-base">
                                溫度-18度~25度
                                <br />
                                小型、中型、大型貨車車隊
                            </h5>
                        </div>
                        <div className="sidebar-text-item mb-10">
                            <h6 className="font-normal text-primary text-sm uppercase mb-0">
                                可配送範圍
                            </h6>
                            <h5 className="text-base">
                                運送全省物流中心
                                <br />
                                配送北區門市
                            </h5>
                        </div>
                        <div className="sidebar-text-item mb-10">
                            <h6 className="font-normal text-primary text-sm uppercase mb-0">
                                服務時間
                            </h6>
                            <h5 className="text-base">
                                365天日以繼夜不間斷服務
                            </h5>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="content-block mb-[60px]">
                            {data?.headings[0] && (
                                <h2 className="mb-5 md:mb-10">
                                    {data?.headings[0]?.content}
                                </h2>
                            )}
                            {data?.headings[1] && (
                                <h5 className="pt-3 xs:pt-0 text-base lg:text-[20px] text-blue-400">
                                    <i className="fi fi-maps-and-flags mr-6 " />{" "}
                                    {data?.headings[1]?.content}
                                </h5>
                            )}
                        </div>
                        <div className="grid grid-cols-1 xs:grid-cols-2 gap-x-8 lg:gap-x-20 mb-[-40px]">
                            <div className="flex-col items-center justify-center pb-10">
                                <p className="text-center font-bold text-lg xs:text-xl pb-10">
                                    可配送範圍
                                </p>
                                <div className=" w-[250px] h-[352px] ml-10 sm:ml-3 md:ml-0 lg:ml-20">
                                    <img
                                        src={"https://i.imgur.com/K7u7AMR.png"}
                                        alt={"配送範圍"}
                                    />
                                </div>
                            </div>
                            <div className="grid xs:col-span-1">
                                {data?.items &&
                                    data?.items.map((item) => (
                                        <FunFact
                                            key={item.id}
                                            countNumber={item.countNumber}
                                            title={item.title}
                                            icon={item.icon}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

AboutUsSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default AboutUsSection;
