import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import PageBreadcrumb from "../components/pagebreadcrumb";
import AboutUsSection from "../container/about-us-area/layout-01";
import TeamSection from "../container//team-area";
import FeatureSection from "../container/feature";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import TestimonialSectionThree from "../container/testimonial/layout-03";
import History from "../components/history";
import AdvantagesSection from "../container/advantages";
import Services from "../components/services";

const AboutUsPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="About Us page" pathname="/about-us" />

            <Header data={{ ...globalContent?.["menu"] }} />
            <div className="">
                <PageBreadcrumb
                    pageContext={pageContext}
                    location={location}
                    title="中衛貨運"
                />
                <AboutUsSection data={content?.["about-us-section"]} />
                {/* <InvestorAndDevelopmentSection
                data={content?.["investor-and-development-section"]}
            /> */}
                <TestimonialSectionThree
                    data={content?.["testimonial-section"]}
                />
                {/* <FeatureSection data={content?.["features-section"]} /> */}
                {/* <TeamSection data={content?.["team-section"]} /> */}

                {/* <History /> */}

                <Footer data={{ ...globalContent?.["footer"] }} />
            </div>
        </Layout>
    );
};

AboutUsPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query AboutUsPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "aboutUsPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
            }
        }
    }
`;
export default AboutUsPage;
